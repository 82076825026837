<template>
  <div id="testInfo">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">鉴定考试信息</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="考试工种" class="searchboxItem ci-full">
              <span class="itemLabel">考试工种:</span>
              <el-select
                v-model="occupationId"
                clearable
                size="small"
                placeholder="请选择工种"
              >
                <el-option
                  v-for="(item, index) in occupationList"
                  :key="index"
                  :label="item.occupationName"
                  :value="item.occupationId"
                >
                </el-option>
              </el-select>
            </div>

            <div title="考试级别" class="searchboxItem ci-full">
              <span class="itemLabel">考试级别:</span>
              <el-select
                v-model="examLevel"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in ExaminationLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div title="考试时间" class="searchboxItem ci-full">
              <span class="itemLabel">考试时间:</span>
              <el-date-picker
                v-model="exportDate"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </div>

            <div title="所在区域" class="searchboxItem ci-full">
              <span class="itemLabel">所在区域:</span>
              <el-cascader
                  :options="areatreeList"
                  clearable
                  filterable
                  :props="propsarea"
                  size="small"
                  v-model="areaId"
              ></el-cascader>
            </div>

            <el-button
                class="bgc-bv"
                style="margin: 0 10px"
                round
                @click="getData()"
            >搜索</el-button>
          </div>
          </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                minWidth="250"
                show-overflow-tooltip
                width="150"
                fixed
              />
              <el-table-column
                label="所在区域"
                width="150"
                align="left"
                prop="areaName"
                minWidth="180"
                show-overflow-tooltip
              />

              <el-table-column
                label="考试工种"
                width="180"
                align="left"
                minWidth="150"
                prop="examOccupationName"
                show-overflow-tooltip
              />

              <el-table-column
                label="考试级别"
                align="left"
                prop="examLevel"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试时间/地点"
                align="center"
                minWidth="330"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div style="text-align: left">
                    <p>
                      理论考试时间:{{ scope.row.examTheoryStart | moment }} -
                      {{ scope.row.examTheoryEnd | moment }}
                    </p>

                    <p>理论考试地点:{{ scope.row.examTheoryPlace }}</p>
                    <p>
                      实操考试时间:{{ scope.row.examPracticeStart | moment }} -
                      {{ scope.row.examPracticeEnd | moment }}
                    </p>

                    <p>实操考试地点:{{ scope.row.examPracticePlace }}</p>
                    <p>
                      综合评审时间:{{ scope.row.examReviewStart | moment }} -
                      {{ scope.row.examReviewEnd | moment }}
                    </p>

                    <p>综合评审地点:{{ scope.row.examReviewPlace || "--" }}</p>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="理论考试时间"
                align="left"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  (scope.row.examTheoryStart || "--") +
                  " - " +
                  (scope.row.examTheoryEnd || "--")
                }}</template>
              </el-table-column>

              <el-table-column
                label="理论考试地点"
                align="left"
                width="250"
                prop="examTheoryPlace"
                show-overflow-tooltip
              />

              <el-table-column
                label="实操考试时间"
                width="260"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  (scope.row.examPracticeStart || "--") +
                  " - " +
                  (scope.row.examPracticeEnd || "--")
                }}</template>
              </el-table-column>

              <el-table-column
                label="实操考试地点"
                align="left"
                width="250"
                prop="examPracticePlace"
                show-overflow-tooltip
              />

              <el-table-column
                label="综合评审时间"
                width="260"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  (scope.row.examReviewStart || "-") +
                  " - " +
                  (scope.row.examReviewEnd || "-")
                }}</template>
              </el-table-column>

              <el-table-column
                label="综合评审地点"
                align="left"
                prop="examReviewPlace"
                width="250"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.examReviewPlace || "--"
                }}</template>
              </el-table-column> -->
              <el-table-column
                label="报名截止时间"
                width="160"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.examEndTime || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="考试名额"
                align="left"
                prop="examPeople"
                show-overflow-tooltip
              />

              <el-table-column
                label="剩余名额"
                align="left"
                prop="examPeopleSurplus"
                show-overflow-tooltip
              />

              <el-table-column
                label="操作"
                align="center"
                fixed="right"
                width="250"
              >
                <div slot-scope="scope" class="flexcc">
                  <!-- <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="onShow(scope.row)"
                    >学员名单</el-button
                  > -->
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleBatchImport(scope.row)"
                    >下载专属报名二维码图片</el-button
                  >
                </div>
              </el-table-column>
              <!-- <Empty slot="empty" /> -->
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      width="60%"
      :before-close="handleClose"
    >
      <component
        v-if="dialogShow"
        :is="componentName"
        :id="id"
        @close="close"
      ></component>
    </el-dialog>

    <!-- 批量导入学员 -->
    <el-dialog
      :title="'批量导入学员'"
      :visible.sync="dialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              >考生导入模板</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <!-- <div style="display:flex;align-items: center;padding-left: 0;background: #f3f3f3;color: red;margin:1rem 0"> 
                <img src="../../../assets/icon3.png" alt="" >
                   <span style="margin:1rem .25rem"
                >请注意，学员加入到与您选择的培训区域和培训类型完全匹配的班级，才算入班成功。</span
              >
              </div> -->
          <el-form
            :inline="true"
            ref="DialogForm"
            :model="ruleDialogForm"
            :rules="rules"
            style="align-items: center; display: flex"
          >
            <el-form-item label="所属培训机构" prop="compId">
              <el-select
                size="small"
                v-model="ruleDialogForm.compId"
                remote
                allow-create
                :remote-method="getCompanyLists"
                filterable
                clearable
                placeholder="请输入两个字搜索"
              >
                <el-option
                  v-for="item in companyLists"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <!-- <p  style="margin-top: 10px; margin-left: 10px">
                上传学员数据表格
                <span style="color: #f46173">{{ fileName }}</span>
              </p> -->
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button
                  class="bgc-bv"
                  style="font-size: 12px; width: 50px; height: 29px"
                  :loading="uploadloading"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                @click="doExport('DialogForm')"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="margin: 1rem 0">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";

export default {
  components: {
    // Operation: (resolve) => {
    //   require(["./operation.vue"], resolve);
    // },
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // ----------------  验证 -------------------------
      occupationId: "", // 考试工种
      examLevel: "", // 考试等级
      //考试级别
      ExaminationLevelList: [
        {
          value: "一级",
          label: "一级",
        },
        {
          value: "二级",
          label: "二级",
        },
        {
          value: "三级",
          label: "三级",
        },
        {
          value: "四级",
          label: "四级",
        },
        {
          value: "五级",
          label: "五级",
        },
      ],
      exportDate: "", // 考试时间

      // ----------------  验证 -------------------------

      CompanyList: [],
      areaId: "", // 地区
      compId: "", // 机构id
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      ruleDialogForm: {
        compId: "",
      },
      rules: {
        compId: [
          { required: true, message: "请选择所属机构", trigger: "change" },
        ],
      },
      dialogShow: false, // 弹窗显示状态
      componentName: "", // 弹窗要显示的组件
      dialogTitle: "", //
      dialogVisible: false,
      totalNum: 0,
      correctNum: 0,
      errorNum: 0,
      fileName: "",
      companyLists: [],
      fileKey: "",
      id: "",
      evaluateCompshow: false,
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getData();
    this.getareatree();
    this.getOccupationList();
  },
  methods: {
    // 获取工种列表
    getOccupationList() {
      this.$post("/evaluate/exam/occupation/all")
        .then((res) => {
          
          this.occupationList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    onShow(row) {
      console.log(row.examId);
      this.id = row.examId;
      this.dialogShow = true;
      this.componentName = "Operation";
      this.dialogTitle = "已报名学员名单";
    },
    //下载模板
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/考生导入模版.xls";
      link.setAttribute("download", "考生导入模版.xls");
      document.body.appendChild(link);
      link.click();
    },
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      if (regPos.test(val)) {
        this.evaluateCompshow = true;
      } else {
        this.evaluateCompshow = false;
      }
    },
    doCloseload() {
      this.dialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.ruleDialogForm = {};
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
      this.$refs["DialogForm"].resetFields();
      this.getData();
    },
    // 下载二维码
    handleBatchImport(row) {
      console.log("考试id");
      let data = {
        examId: row.examId,
      };
      this.$post("/evaluate/exam/comp/getUnlimited", data)
        .then((res) => {
          console.log(res.message);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.message;
          link.setAttribute("download", "报名二维码");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          return;
        });
    },
    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },

    // 关闭弹窗
    close() {
      this.dialogShow = false;
      this.getData();
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      // 鉴定考试工种id
      if (this.occupationId) {
        params.examOccupationId = this.occupationId;
      }

      // 考试级别
      if (this.examLevel) {
        params.examLevel = this.examLevel;
      }

      if (this.exportDate) {
        params.examTheoryStart = this.exportDate[0];
        params.examTheoryEnd = this.exportDate[1];
      }
      if (this.compId) {
        params.compId = this.compId;
      }

      if (this.areaId) {
        params.areaId = this.areaId;
      }

      this.doFetch({
        url: "/evaluate/exam/comp/page",
        params,
        pageNum,
      });
      
    },
    // 编辑
    onEdit(row) {
      this.id = row.compId;
      this.dialogTitle = "编辑鉴定机构";
      this.componentName = "Operation";
      this.dialogShow = true;
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 导入学员上传文件
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    //开始导入学员
    doExport(formName) {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.fileKey) {
              // const parmar = {
              //   examId: this.id,
              //   evaluateCompId: this.ruleDialogForm.compId,
              //   url: this.fileKey,
              // };
              this.isNumber(this.ruleDialogForm.compId);
              const parmar = {
                examId: this.id,
                url: this.fileKey,
              };
              if (this.evaluateCompshow) {
                parmar.evaluateCompId = this.ruleDialogForm.compId; //所属培训机构
              } else {
                parmar.evaluateCompName = this.ruleDialogForm.compId; //所属培训机构
              }
              this.$post("/evaluate/user/batchImport", parmar)
                .then((res) => {
                  if (res.status == 0) {
                    this.totalNum = res.data.totalNum;
                    this.errorNum = res.data.errorNum;
                    this.correctNum = res.data.correctNum;
                    this.importType = res.data.importType;
                    this.batchId = res.data.batchId;
                    this.$message({
                      type: "success",
                      message: "导入成功",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          }
        });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post("/evaluate/user/exportErrorUser", {
          batchId: this.batchId,
          importType: this.importType,
        }).then((res) => {
          if (res.status == 0) {
            window.open(res.message);
          }
        });
      }
    },
    /* 机构名称 */
    getCompanyLists(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyLists = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyLists = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scope>
#testInfo {
  .exportdialog {
    .el-dialog__title {
      font-size: 16px;
    }
    .export-box {
      width: 100%;
      margin-bottom: 20px;
      h1 {
        padding: 0 5px 10px;
        display: flex;
        align-items: flex-end;
        font-size: 40px;
        border-bottom: 1px dashed #ccc;
        span {
          margin-left: 20px;
          font-size: 14px;
        }
      }
      > div {
        padding-left: 30px;
      }
      .el-button.is-disabled,
      .el-button.is-disabled:focus,
      .el-button.is-disabled:hover {
        color: #fff;
        background: #c3c3c3;
        border: none;
      }
    }
    .el-form-item {
      display: flex;
      align-items: center;
    }
  }
  .exportdialog {
    .upload-workers {
      height: 30px;
      .el-upload {
        height: 30px !important;
        width: 97px;
        border: none !important;
        .el-button {
          padding: 5px 0;
        }
      }
    }
  }
  .el-textarea__inner {
    min-height: 100px !important;
  }
  .studentMation {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
      margin-bottom: 5px;
    }
  }
}
</style>
